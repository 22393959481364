.box {
    background: white;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
    animation-direction: alternate;
}

.box:hover {
    background-color: #0463D5;
    cursor: pointer;
    color: white;
}