* {
    padding: 0;
    margin: 0;
}
html,
body {
    overflow-x: hidden;
}

body {
    position: relative
}